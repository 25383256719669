#policy_embed_div {
    background-color: #ebf3f7;
    height: 100%;
    padding: 20px 0;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    padding: 20px;
    height: auto;
}

.disclaimer-container {
    max-width: 80%;
    margin: auto;
    padding-top: 80px;
}