footer {
    background-color: #cce0eb;
    font-size: 0.85rem;
    padding: 75px 20px 100px 20px;
    text-align: center;
}

.footer_logo {
    max-width: 100%;
    width: 25%;
    position: relative;
    text-align: center;
    margin: 0 auto;
    align-items: center;
}

.footer_logo img {
    max-width: 100%;
    text-align: center;
    justify-self: center;
}

.link_items {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.link_items>li {
    text-decoration: none;
    list-style: none;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 1.0rem;
}

small {
    display: block;
    margin-top: 30px;
    font-size: 1rem;
    color: #333;
}

@media (max-width: 600px) {
    .footer_logo {
        width: 55%;
    }
    small {
        font-size: 1.0rem;
    }

    .link_items {
        display: block;
    }

    .link_items>li {
        margin: 10px auto;
    }
}