.offer_post {
    margin: 20px auto;
}

.offer_post h2 {
    display: flex;
    line-height: 35px;
    font-size: 30px !important;
    text-transform: capitalize !important;
    text-decoration: none;
    color: #000000 !important;
}

.offer_post .title__link {
    text-decoration: none;
    color: #000000;
}

.offer_post .title__link:hover {
    text-decoration: underline;
    /* color: #0e22a4; */
}

.offer_image img {
    max-width: 100%;
    min-width: 100%;
    margin: 20px auto;
    width: 100%;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
    width: 100vw;
}

.unit {
    background-color: #595959;
    color: #ffffff;
    padding: 8px 15px 0px 15px;
    margin-right: 10px;
    border-radius: 5px;
}

a {
    text-decoration: underline;
    color: #207aff;
    font-weight: bold;
    letter-spacing: .01em;
}

.button {
    width: 100%;
    color: #fff;
    border-radius: 4px;
    font-weight: bold;
    display: block;
    border: none;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;
    /* background-color: #db2828; */
    background: linear-gradient(160deg,#00FFDC,#26C3F7,#279DD7,#2685F7,#267BF7,#1C77E4,#2269BE,#135CB4);
    padding: 14px 0;
    font-size: 1.71428571rem;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.button:hover {
    background-color: #f60707;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

@media (max-width: 600px) {
    .offer_image img {
        margin: 0px auto;
        max-width: 100%;
        width: 100%;
    }

    .offer_post h2 {
        font-size: 25px !important;
    }
}