.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    background-color: rgb(125, 192, 73);
    color: #fff;
    border: none;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    /* padding: 10px 15px; */
    cursor: pointer;
    font-size: 16px;
    /* width: 10%; */
  }
  
  .scroll-to-top.visible {
    display: block;
  }
  