.intro_sec {
    position: relative;
    padding-top: 25px;
}

.author_image img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 20px;
    max-width: 100%;
    display: none;
}

.intro_image img {
    max-width: 100%;
    width: 100%;
}

.intro_details {
    display: flex;
    align-items: center;
    margin: 0px auto;
    text-align: center;
    justify-content: center;
}

.aut_d {
    align-self: center;
    color: #000;
    font-weight: 600;
    font-size: 14px !important;
}

.aut_d span {
    font-weight: 600;
    color: #000;
}

.date{
    font-weight: 400 !important;
    color: #999 !important;
}

.intro_text p {
    margin: 0px auto;
    text-align: left;
    line-height: 27px;
    margin-bottom: 20px;
}

@media (max-width: 600px) {
    .intro_text p {
        margin: 20px auto;
        text-align: left;
        line-height: 27px;
    }
    .intro_details {
        margin: 0px auto;
    }
    .intro_details p {
        margin: 5px auto;
    }

    .intro_image img {
        max-width: 100%;
        width: 100%;
    }
}